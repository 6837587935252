@media (max-width: 64em){
    .loader-bar {
    width: 100%;
    height: 100%;
    display: grid;
    
    margin-top:5%;
    margin-bottom:5%;
    margin-left:25%;
    grid-template-columns: repeat(1,2px) repeat(11, 3px 2px) repeat(2,2px);
    grid-template-rows: repeat(6,2px);
    }
  }


.loader-bar {
  width: 100%;
  height: 100%;
  display: grid;
  
  margin-top:5%;
  margin-bottom:5%;
  margin-left:25%;
  grid-template-columns: repeat(1,6px) repeat(11, 9px 6px) repeat(2,6px);
  grid-template-rows: repeat(6,6px);


}

.block-border {
  background-color: #3b1424;
}

.block-meter {
  background-color: rgb(30, 184, 30);
}


.block-border:nth-of-type(1) {
  grid-row: 1;
  grid-column: 3/-3;
}

.block-border:nth-of-type(2) {
  grid-row: -2;
  grid-column: 3/-3;
}

.block-border:nth-of-type(3) {
  grid-row: 3/5;
  grid-column: 1;
}

.block-border:nth-of-type(4) {
  grid-row: 3/5;
  grid-column: -2;
}

.block-border:nth-of-type(5) {
  grid-row: 2;
  grid-column: 2;
}

.block-border:nth-of-type(6) {
  grid-row: 2;
  grid-column: -3;
}

.block-border:nth-of-type(7) {
  grid-row: 5;
  grid-column: 2;
}

.block-border:nth-of-type(8) {
  grid-row: 5;
  grid-column: -3;
}

.block-meter:nth-of-type(9) {
  grid-row: 3/5;
  grid-column: 4;
}

.block-meter:nth-of-type(10) {
  grid-row: 3/5;
  grid-column: 6;
}

.block-meter:nth-of-type(11) {
  grid-row: 3/5;
  grid-column: 8;
}

.block-meter:nth-of-type(12) {
  grid-row: 3/5;
  grid-column: 10;
}

.block-meter:nth-of-type(13) {
  grid-row: 3/5;
  grid-column: 12;

}

.block-meter:nth-of-type(14) {
  grid-row: 3/5;
  grid-column: 14;
}

.block-meter:nth-of-type(15) {
  grid-row: 3/5;
  grid-column: 16;

}

.block-meter:nth-of-type(16) {
  grid-row: 3/5;
  grid-column: 18;

}
.block-meter:nth-of-type(17) {
  grid-row: 3/5;
  grid-column: 20;

}
.block-meter:nth-of-type(18) {
  grid-row: 3/5;
  grid-column: 22;
  
}

@keyframes blinky1 {
  99% {opacity: 1;}
  100% {opacity: 1;}
}

@keyframes blinky2 {
  10% {opacity: 1;}
  20% {opacity: 1;}
  30% {opacity: 1;}
  90% {opacity: 1;}
}